import { useHotMatchApi } from '~/api/hotmatch'
import { useLoading } from '~/composables/useLoading'
import { RESPONSE_STATUS } from '~/config/constant'

export default () => {
  const { isLoading, loading, load } = useLoading()
  const { $axios } = useNuxtApp()
  const { GET_HOT_MATCH } = useHotMatchApi()

  const limit = 10

  const matches = ref([])
  loading()
  const fetchHotMatch = async () => {
    try {
      const payload = { params: { limit } }
      const { data: response } = await $axios.get(GET_HOT_MATCH, payload)

      if (response.status === RESPONSE_STATUS.STATUS_OK && response.data?.length) {
        matches.value = response.data
      }
      load()
    } catch (error) {
      load()
      console.log(error)
    }
  }

  return {
    matches,
    isLoading,
    fetchHotMatch
  }
}
