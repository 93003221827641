<template>
  <div class="slots">
    <div class="slots__wrapper container">
      <div class="slots__top">
        <h2 class="slots__title">Nổ hũ</h2>
        <nuxt-link class="slots__more" :to="LOBBY_GAME_URLS.SLOTS_GAME">Xem tất cả</nuxt-link>
      </div>
      <div class="slots__bottom">
        <div class="slots__left">
          <div class="slots__content">
            <GameItem v-for="(item, index) in listSlotsGames" :key="index" :item="item" radius="8" show-name />
          </div>
        </div>
        <div class="slots__right">
          <nuxt-link class="slots__item" :to="LOBBY_GAME_URLS.SLOTS_GAME">
            <div class="slots__wrap-img">
              <BaseImg class="image" src="/assets/images/components/desktop/pages/home/slots/banner.webp" />
              <div class="jackpot">
                <client-only>
                  <AnimateNumber :number="sumJackpot['jackpotNohu']" :from-number="100000000" />
                </client-only>
              </div>
            </div>
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import queryString from 'query-string'
import { useAppStore } from '~/store/app'
import GameItem from '~/components/common/game-item.vue'
import AnimateNumber from '~/components/common/animate-number.vue'
import { useGame } from '~/composables/game/useGame'
import { IGameItem } from '~/types/common'
import { LOBBY_GAME_URLS } from '~~/config/page-url'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot } = storeToRefs(store)

const { fetchGames } = useGame()

const listSlotsGames = ref<IGameItem[]>([])

const fetchSlotsGames = async () => {
  const queryUrl = queryString.stringify({
    path_alias: 'no-hu',
    limit: 8,
    page: 1
  })
  const { data: response } = await fetchGames(queryUrl)
  if (response.status === 'OK') {
    listSlotsGames.value = response.data?.items
  }
}

onMounted(async () => {
  await fetchSlotsGames()
})
</script>

<style scoped lang="scss" src="~/assets/scss/components/desktop/pages/home/slots/index.scss"></style>
