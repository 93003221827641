<template>
  <div class="lottery">
    <div class="lottery__wrapper container">
      <div class="lottery__top">
        <h2 class="lottery__title">Lô đề</h2>
        <nuxt-link class="lottery__more" :to="LOBBY_GAME_URLS.LO_DE">Xem tất cả</nuxt-link>
      </div>
      <div class="lottery__bottom">
        <div class="lottery__content">
          <GameItem
            v-for="(item, index) in listLotteryGames"
            :key="index"
            :item="item"
            radius="8"
            show-name
            show-coin
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import queryString from 'query-string'
import GameItem from '~/components/common/game-item.vue'
import { useGame } from '~/composables/game/useGame'
import { IGameItem } from '~/types/common'
import { LOBBY_GAME_URLS } from '~~/config/page-url'

const { fetchGames } = useGame()

const listLotteryGames = ref<IGameItem[]>([])

const fetchLotteryGames = async () => {
  const queryUrl = queryString.stringify({
    path_alias: 'table-game', // change path_alias to 'lo-de' later
    limit: 6,
    page: 1
  })
  const { data: response } = await fetchGames(queryUrl)
  if (response.status === 'OK') {
    listLotteryGames.value = response.data?.items
  }
}

onMounted(async () => {
  await fetchLotteryGames()
})
</script>

<style scoped lang="scss" src="~/assets/scss/components/desktop/pages/home/lottery/index.scss"></style>
