<template>
  <div class="home-info">
    <h3 class="home-info__title">{{ $config.BRANCH_NAME }} - Nhà cái cá cược uy tín nhất Châu Á, nạp rút siêu tốc.</h3>
    <p class="home-info__desc">
      {{ $config.BRANCH_NAME }} là một trang web cờ bạc hàng đầu dành cho người chơi Việt Nam. Chơi
      {{ $config.BRANCH_NAME }} với nhiều thể loại: Xổ số, Game Bài, Nổ Hũ và cá cược Thể Thao. Đăng ký ngay để nhận
      tiền thưởng hàng ngày!
    </p>
  </div>
</template>

<script setup lang="ts">
const { $config } = useNuxtApp()
</script>

<style scoped lang="scss" src="~/assets/scss/components/desktop/pages/home/home-info/index.scss"></style>
